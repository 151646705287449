@charset "UTF-8";

$contet_width_sp:94%;



$col_white:#ffffff;
$col_white_20:rgba(255,255,255,0.2);
$col_white_25:rgba(255,255,255,0.5);
$col_dgray:#555555;
$col_gray:#cccccc;
$col_lgray:#dddddd;

$col_green:#33995e;
$col_mgreen:#799933;
$col_link:#339999;
$col_yellow:#fdd000;
$col_brown:#996c33;
$col_wine:#983333;

$col_red:#dd0000;




$col_theme:#04C9D1;
$bg_thema:#FFE7A8;

$col_theme2:#4eaab8;


$col_text:#222222;
$col_text_gray:#666666;
$col_a:#1100dd;
$col_a_visited:#6600aa;

$bg_dgray:#666666;
$bg_gray:#999999;
$bg_lgray:#bbbbbb;
$bg_lgray2:#f4f4f4;


$col_status_wanted:#EA4656;
$col_status_end:#999;
$col_status_old:#666;






h1{
	font-size: 28px;
	line-height: 140%;
	font-weight: 400;
	margin-top: 10px;
	margin-bottom: 10px;
}
h2{
	font-size:20px;
	line-height: 140%;
	font-weight:400;
	margin-bottom:30px;


	&.ttl_corner_name{
		font-size: 30px;
	}
}
// @at-root .page #content h2{
// 	text-align:center;
// 	margin-top:100px;
// 	margin-bottom:40px;
// 	border:none;
// }

h3{
	font-size:18px;
	line-height:140%;
	font-weight:400;
	margin-bottom:30px;
	padding-bottom: 0.2em;
	// border-bottom: solid 1px $col_theme;
	
}


h4{
	font-size:16px;
	line-height:140%;
	font-weight:400;
	margin-bottom:30px;
}


dl.dl_contact{
	dt{
		width:100%;
		padding-bottom:8px;
	}
	dd{
		width:100%;
		padding:0 0 12px 0;
	    float: left;
	}
}


.ipt_size_m
,.ipt_size_l{
	width:92%;
	width: calc(100% - 16px);
}


.sp_only{
	display: block;
}
.pc_only{
	display: none;
}
.sp_only_inline{
	display: inline-block;
}
.pc_only_inline{
	display: none;
}






header{
	.header_inner{
		width:$contet_width_sp;
		.header_logo{
			margin-bottom: 10px;
			#site_name{
				margin-bottom: 0px;
				font-size:30px;
			}

		}

		nav{
			margin-top:0px;

			ul{
				border:none;
				li{
					width:49%;
					width: calc(50% - 1px);
					margin-left:1px;
					border:none;
					a
					,a:link
					,a:visited{
						padding:5%;
						margin:0;
						margin-bottom:1px;
						width:90%;
						color:#fff;
						background-color: #04C9D1;
						border:none;
						text-align:center;
					}

					&.menu-item-home{
						display: block;
					}
				}
			}
		}
	}
}



#content{
	width:$contet_width_sp;
	text-align: left;

	#section_news{
		.section_news_inner{
			width:$contet_width_sp;
		}

		dl{
			width:100%;
			dt{
				font-size:12px;
				padding:16px  0 0px 00;
				width:100%;
			}
			dd{
				padding:4px  0 12px 00;
				clear: both;
				width:100%;
				float:left;
				font-size:14px;
				line-height: 140%;
			}
		}
	}












	#section_ranking .section_ranking_inner{
		width:$contet_width_sp;

		.ranking_list{
			.block_ranking_list{
				width:100%;

				border-top: none;
				border-bottom: none;
				a{
					width:100%;
					text-align: left;
					display:block;
					height:auto;
					font-size:16px;
					line-height: 140%;
					padding-left: 60px;
				}

				.rankin_num{
					left:0;
					right:auto;
				}
				.rankin_num_underline{
					display: none;
				}
			}
			#ranking_list_1{
				margin-top: 0px;
				padding: 20px 0 40px 0;
				height:auto;
				a{
					height:auto;
				}

				.rankin_num{
					top:-30px;
					img{
						padding-left: 8px;
					}
				}
			}
			#ranking_list_2{
				margin-top: 0px;
				padding: 20px 0 40px 0;
				height:auto;
				a{
					height:auto;
				}
				.rankin_num{
					top:-20px;
					img{
						padding-left: 8px;
					}
				}
			}
			#ranking_list_3{
				margin-top: 0px;
				padding: 20px 0 40px 0;
			    height:auto;
				a{
					height:auto;
				}
				.rankin_num{
					top:-10px;
					img{
						padding-left: 12px;
					}
				}
			}
		}
	}





	#section_blog .section_blog_inner{
		width:$contet_width_sp;


	}



	#section_theater .section_theater_inner{
		width:$contet_width_sp;

	}



	#section_column .section_column_inner{
		width:$contet_width_sp;
		
		.box_today_word{
			.lbl_today_word{
				font-size:13px;
				text-align: center;
			}
			.today_word{
				font-size:20px;
			}
			.today_word_answer{
				font-size:16px;
				width:100%;
				margin:-18px auto 0;
				text-align: left;
				img{
					display: block;
					margin:10px auto;
				}
			}

			input#check_today_word:checked + .today_word_answer p{
				font-size:16px;
			}

		}


		p{
			text-align: left;
			font-size:14px;
		}



		.member_list_thumb .block_member_list_thumb{

			    width: 80%;
			    padding:0 10%;
			    margin-right:0;
			    margin-bottom: 12px;

				&:nth-of-type(2n){
					padding-right:0;
					margin-right:0;

					padding-left:4%;
					border-right: none;
				}


				.member_body{
					h4{
						font-size:14px;
					}
					p{
						font-size:12px;
					}
				}
		}
	}



	#section_lesson .section_lesson_inner{
		width:$contet_width_sp;
		p{
			text-align: left;
			font-size:15px;
			line-height: 200%;
		}

	}


	#section_about{
		text-align: center;

		.about_contents_outer{
			background-position-x:0;
		}
		.about_contents{
			width:90%;
			padding-right:0;

			.txt_name_jp,
			.txt_name_en{
				text-align: center;
			}

			.box_sns{
				text-align: center;
			}


			.box_profile{
				padding:4px 24px 24px 24px;
			}
		}
	}




	.section_sns .section_sns_inner{
		width:$contet_width_sp;
		
		.sns_facebook{
			width:100%;
			margin-left: 0;
		}
		.sns_twitter{
			width:100%;
			margin-left: 0;
		}
		.sns_line{
			width:100%;
		}
		.sns_insta{
			width:100%;
		}

		p{
			text-align: left;
			font-size:15px;
			line-height: 200%;
		}

		p.center{
			text-align: center;
		}


	}


	.post_list_thumb .block_post_list_thumb{
		width:48%;
		padding-right:0;
		margin-right:0;
		margin-bottom:10px;

		&:nth-of-type(2n){
			float:right;
		}

		.post_pic{
			width:100%;
		}
		.post_body{
			.post_title{
				font-size:14px;
			}
		}

	}


	.lesson_list_thumb .block_lesson_list_thumb{
		width:48%;
		padding-right:0;
		margin-right:0;
		margin-bottom:10px;

		&:nth-of-type(2n){
			float:right;
		}

		.lesson_pic{
			width:100%;
		}
		.lesson_body dl{

		 	border-top: solid 1px #dddddd;
		 	dt{
		 		padding-top: 12px;
		 	}
			dd{
				float: left;
		  		clear: both;
		  		padding:0 0 10px 0;
		  		border-top: none;
		  		border-bottom: solid 1px #dddddd;
		  		width:100%;
				&:last-child{
					padding-bottom:10px;
		  			border-bottom: none;
				}
				.instructor_list{
					width:100%;
					text-align: center;
					.instructor_pic{
						float: none;
						margin: 10px auto 0;
					}
					.instructor_body{
					    margin-left: 0px;
					}
				}
			}
		}

	}


	.entry-title{
		text-align: left;
		font-size:24px;
	}
	@at-root .page #content .entry-title{
		text-align: center;
		font-size:30px;

	}
	.entry-meta{
		text-align: left;
	}
	.entry-content{
		width:100%;
		h2{
			font-size: 22px;
		}
		h3{
			font-size: 20px;
		}
		h4{
			font-size: 18px;
		}
		h5{
			font-size: 16px;
		}
		h6{
			font-size: 16px;
		}
		p{
			font-size: 14px;
		}
		
	}
	@at-root #content .content_width_s{
			width:100%;
	}

	@at-root .contact #content .entry-content
	,.single #content .entry-content{
		width:100%;
	}

	@at-root .page #content .entry-content h3.ttl_l{
		font-size: 26px;
	}



	#prev_next{
		padding:0;
		.post_prev
		,.post_next{
			width:48%;
			
			padding:0;

			margin:16px 0;

			padding:0px 0;
			&:after{
				content:"";
			}


			.lbl_prev_next{
				font-size:14px;
				margin:4px 0 4px 0;	
			}
			.block_post_list_thumb {
				width:100%;
				

				.post_body{
					width:95%;
					padding-left:0%;
				}
			}

		}
		.post_next{
			border-bottom:none;
		}
	}

	.post_link{

		.block_post_list_thumb{
			.post_pic{
				width:120px;
			}
			.post_body{
				width:60%;
				width: calc(100% - 140px);
				dl{
				    margin-top: 10px;
					dt{
						padding:4px 0;
						font-size: 13px;
					}
					dd{
						padding:4px 0;
						font-size: 13px;
					}
				}
			}
		}

	}



}

#secondary{
	h2{
		font-size:26px;
	}
	h3.ttl_ss{
		margin-top: -20px;
	}

}

footer{
	.footer_inner{
		width:$contet_width_sp;
		padding:20px 0;
	}
	li{
		width: 50%;
		float:left;
	}
	a{
		margin:4px 0;
	}
	#copyright{
		font-size:12px;
	}

}





#page_top{

	right:4px;
	bottom:4px;
}